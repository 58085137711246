<template>
  <div>
    <section class="lightColor review_date reference">
      <div class="review" v-if="company">
        <v-row class="mr-5 pr-5">
          <v-col sm="2" class="text-right">
            <b-avatar
              size="120"
              variant="info"
              :src="company['company_logo']"
            ></b-avatar>
          </v-col>
          <v-col sm="10">
            <b
              ><p class="font-size-20">{{ company["company_name"] }}</p></b
            >
            <p class="font-size-14 text-muted">
              {{ company["tag_line"] }}
            </p>
            <p class="font-size-12">
              <b-form-rating
                v-model="rating"
                class="myrating pl-0"
                inline
                readonly
              ></b-form-rating>
              <span class="txt-brown cs-span">68 Reviews</span>
            </p>
          </v-col>
        </v-row>
      </div>
    </section>
    <section class="mr-15 ml-15 p-5">
      <v-row class="mr-5 pl-1">
        <h4>Write a Reference</h4>
        <v-col sm="4">
          <label for="fname" class="font-size-14">First Name</label>

          <v-text-field
            outlined
            dense
            hide-details
            id="fname"
            class="mt-2 font-size-12 bg-transparent"
            placeholder="First Name"
            v-model="first_name"
          ></v-text-field>
        </v-col>
        <v-col sm="4">
          <label for="cname" class="font-size-14">Last Name</label>

          <v-text-field
            outlined
            dense
            hide-details
            id="lname"
            class="mt-2 font-size-14 bg-transparent"
            placeholder="Last Name"
            v-model="last_name"
          ></v-text-field>
        </v-col>
        <v-col sm="4">
          <label class="font-size-14">Email</label>
          <v-text-field
            outlined
            dense
            hide-details
            id="email"
            class="mt-2 font-size-14 bg-transparent"
            placeholder="Email"
            v-model="email"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mr-5 pl-1">
        <v-col sm="4">
          <label for="fname" class="font-size-14">Phone Number</label>

          <v-text-field
            outlined
            dense
            hide-details
            id="number"
            class="mt-2 font-size-12 bg-transparent"
            placeholder="Phone Number"
            v-model="phone_number"
          ></v-text-field>
        </v-col>
        <v-col sm="4">
          <label for="cname" class="font-size-14">Company Name</label>

          <v-text-field
            outlined
            dense
            hide-details
            id="cname"
            class="mt-2 font-size-14 bg-transparent"
            placeholder="Company Name"
            v-model="company_name"
          ></v-text-field>
        </v-col>
        <v-col sm="4">
          <label class="font-size-14">Company Website</label>
          <v-text-field
            outlined
            dense
            hide-details
            id="website"
            class="mt-2 font-size-14 bg-transparent"
            placeholder="Company Website"
            v-model="company_webiste"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row class="mr-5 pl-1">
        <v-col sm="4" class="pb-0">
          <label for="fname" class="font-size-14">Industry</label>
          <v-select
            :items="items"
            outlined
            dense
            class="mt-2 font-size-12"
            placeholder="Select Industry"
            v-model="industry"
          ></v-select>
        </v-col>
        <v-col sm="4" class="pb-0">
          <label for="cname" class="font-size-14">Headquater City</label>
          <v-text-field
            outlined
            dense
            hide-details
            id="website"
            class="mt-2 font-size-14 bg-transparent"
            placeholder="Headquater City"
            v-model="headquater_city"
          ></v-text-field>
        </v-col>
        <v-col sm="4" class="pb-0">
          <label class="font-size-14">Company Size</label>
          <v-select
            :items="items"
            outlined
            dense
            class="mt-2 font-size-12"
            placeholder="Select Company Size"
            v-model="company_size"
          ></v-select>
        </v-col>
      </v-row>
      <v-row class="mr-5 pl-1">
        <v-col sm="4" class="pt-0">
          <label for="fname" class="font-size-14">Job Title</label>

          <v-text-field
            outlined
            dense
            hide-details
            id="jtitle"
            class="mt-2 font-size-12 bg-transparent"
            placeholder="Job Title"
            v-model="job_title"
          ></v-text-field>
        </v-col>
        <v-col sm="8" class="pt-0">
          <label for="cname" class="font-size-14">Project Title</label>

          <v-text-field
            outlined
            dense
            hide-details
            id="ptitle"
            class="mt-2 font-size-14 bg-transparent"
            placeholder="project Title"
            v-model="project_title"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mr-5 pl-1">
        <v-col sm="4">
          <label for="fname" class="font-size-14">Cost Range</label>

          <v-select
            :items="items"
            outlined
            dense
            class="mt-2 font-size-12"
            placeholder="Select Cost Range"
            v-model="cost_range"
          ></v-select>
        </v-col>
        <v-col sm="4">
          <label class="font-size-14" for="datepicker-dateformat2"
            >Project Start</label
          >
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            :return-value.sync="date1"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date1"
                append-icon="mdi-calendar"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
                class="mt-2"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date1" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu1 = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.menu1.save(date1)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col sm="4">
          <label class="font-size-14" for="datepicker-dateformat2"
            >Project End</label
          >
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                append-icon="mdi-calendar"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
                class="pt-2 mt-0"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(date)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row class="pr-5">
        <v-col>
          <b-button
            variant="outline-dark"
            class="button-custom-blck-company button-height-width font-size-14"
            >Submit Reference</b-button
          >
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Dashboard",

  data: function () {
    return {
      company: null,
      website: null,
      name: null,
      profile: null,
      items: [],
      Sort_By: null,
      rating: 3,
      first_name: null,
      last_name: null,
      email: null,
      phone_number: null,
      company_name: null,
      company_webiste: null,
      industry: null,
      headquater_city: null,
      company_size: null,
      job_title: null,
      project_title: null,
      cost_range: null,
      date: null,
      date1: null,
      updatedDate: null,
      menu: null,
      menu1: null,
      companySlug: null,
    };
  },

  created() {
    this.getdata();
  },

  methods: {
    ...mapActions([
      "getReview",
      "getCompanyProfile",
      "getCompanyCategory",
      "getCostList",
    ]),

    getdata() {
      this.getCompanyProfile(this.$route.params.id).then((response) => {
        this.company = response.data.data;
        this.company;
      });
      this.getCostList().then((response) => {
        response.data.data.forEach((value) => {
          this.cost_list.push(value.name);
        });
      });
      this.getCompanyCategory().then((response) => {
        response.data.data.forEach((value) => {
          if (value["parent_id"] == 0) {
            let s_id = value["id"];

            response.data.data.forEach((v) => {
              if (v["parent_id"] == s_id) {
                this.catregory.push(v["name"]);
              }
            });
          }
        });
      });
    },
  },
};
</script>
<style scoped>
.radious-grey-bg {
  background-color: whitesmoke;
  padding: 15px;
  border-radius: 10px;
}
.cust-width {
  min-width: 48% !important;
}
.cust-height {
  height: 1% !important;
}
.border-with-radious {
  border: 1px solid whitesmoke !important;
  border-radius: 10px !important;
}
.form-control {
  background-color: unset !important;
  padding: 0.13rem !important;
}
.v-text-field--full-width > .v-input__control > .v-input__slot,
.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 40px !important;
}
</style>
